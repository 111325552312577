import PageMain from "@/components/pageMain/PageMain.vue";
import { uploadUrl } from "@/utils/constant.js";
import {
    getNewsList,//获取新闻列表
    addNewsList,//新增
    editNewsList,//编辑
    deleteNewsById,//删除
    getDoSync,//获取微信公众号数据
} from "@/api/dataSet/newsManegment.js";
import {
    getSolution,//获取解决方案
} from "@/api/dataSet/solution";
import {
    timestampToYYYYMMDD,//将时间戳转换为Date对象
} from "@/utils/common.js";
export default {
    components: {
        PageMain,
    },
    data() {
        return {
            elmentPar: {
                pageSize: 50,
                pageNum: 1,
                searchValue: '',
                categorySub:'',//焦点新闻分类
            },
            tableData: [],
            pageTotal: null,
            loading: false,
            dialogNews: {
                title: '',
                visiable: false,
            },
            uploadHeaders: {},
            uploadUrl,
            newForm: {
                category: "",//类别
                createBy: "",
                issueDate: "",
                link: "",
                model: "-1",//默认属于新闻中心
                name: "",
                newsId: null,
                newsPcImg: "",
                newsPhoneImg: "",
                remark: "",
                sort: null,
                source: "",
                desc: '',
                type: '',//素材类型
                categorySub: '',//焦点新闻的板块
                duration: '',//视频时长
            },
            newsRules: {
                name: [
                    {
                        required: true,
                        message: "请输入名称",
                        trigger: "blur",
                    },
                ],
                issueDate: [
                    {
                        required: true,
                        message: "请输入新闻发布日期",
                        trigger: "blur",
                    },
                ],
            },
            belongList: [],
            timestampToYYYYMMDD,//时间戳返回日期
        };
    },
    created() {
        this.uploadHeaders = { Authorization: this.$store.state.user.token };
        this.getNewsList();
    },
    methods: {
        // 获取解决方案
        async getSolution() {
            let res = await getSolution(this.$route.query.lang*1);
            if (res.code === 200) {
                this.belongList = res.data;
            }
        },
        // 获取新闻列表
        async getNewsList() {
            this.loading = true;
            let res = await getNewsList(this.elmentPar);
            if (res.code === 200) {
                this.tableData = res.rows;
                this.pageTotal = res.total;
                this.loading = false;
            } else {
                this.loading = false;
            }
        },
        handleSizeChange(val) {
            this.elmentPar.pageSize = val;
            this.getNewsList();
        },

        handleCurrentChange(val) {
            this.elmentPar.pageNum = val;
            this.getNewsList();
        },
        //编辑新闻
        editNews(type, row) {
            this.dialogNews = {
                title: type === 'add' ? '新增解决新闻' : '编辑【' + row.solutionName + '】',
                visiable: true,
            }
            if (type === "edit") {
                let newForm = Object.assign({}, row);
                this.newForm = newForm;
            } else {
                this.$data.newForm = this.$options.data.call(this).newForm;
            }
            this.getSolution();
        },

        async uploadProgress(file) {
            let socurceType = file.type.split('/')[0];
            if (this.newForm.category === '七腾视频' && socurceType != 'video') {
                // 如果所属模块是视频,且上传元素不等于视频时
                this.$message.error('当前新闻属于视频，请选择视频文件上传');
                return;
            }
        },

        // 上传成功
        async uploadSuccess(res, file, type) {
            let that=this;
            let url = res.data.url;//视频链接
            let fileSize = (file.size / 1024 / 1024);
            if (fileSize > 100) {
                this.$message.error('文件大小超过100MB,请重新上传！');
                return;
            }
            if (res.code !== 200) {
                this.$message.error('上传失败，请稍后再试');
                return;
            }
            this.newForm.type = file.raw.type.split('/')[0];
            if (this.newForm.type === 'video') {
                const newvideoUrl = url;
                let videoObj = document.createElement('video')
                videoObj.preload = 'metadata'
                videoObj.src = newvideoUrl
                videoObj.onloadedmetadata = function (evt) {
                    URL.revokeObjectURL(newvideoUrl)
                    that.newForm.duration = videoObj.duration;//视频时长
                }
            }
            try {
                if (type === 'newPc')
                    this.newForm.newsPcImg = url;
                else
                    this.newForm.newsPhoneImg = url;
                this.newForm.type = file.raw.type.split('/')[0];
                this.$message.success('上传成功');
            } catch (e) {
                this.$message.error('上传失败，请稍后再试');
            }
            this.$refs.avatarUpload.clearFiles();
            this.$refs.avatarUploadPhone.clearFiles();
        },

        //提交
        async submitFrom(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let res = {};
                    if (this.newForm.newsId) {
                        // 编辑
                        res = await editNewsList(this.newForm);
                    } else {
                        // 新增
                        res = await addNewsList(this.newForm);
                    }
                    if (res.code === 200) {
                        this.$message({
                            type: "success",
                            message: `编辑成功!`,
                        });
                        this.dialogNews.visiable = false;
                        this.getNewsList();
                    }
                }
            });
        },

        // 删除
        async deleteNewsById(newsId) {
            let res = await deleteNewsById(newsId);
            if (res.code === 200) {
                this.$message({
                    type: "success",
                    message: `删除成功!`,
                });
                this.getNewsList();
            }
        },

        //同步微信公众号数据
        async getDoSync() {
            this.$confirm(`确定将微信公众号的数据全部同步到新闻中心吗？`, "提示", {
                confirmButtonText: "确认",
                cancelButtonText: "取消",
                type: "info",
            }).then(async () => {
                let res = await getDoSync();
                if (res.code === 200) {
                    this.$message.success(res.msg);
                } else {
                    this.$message.error(res.msg);
                }
                this.getNewsList();
            });
        },
    },
};