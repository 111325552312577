<template>
  <div>
    <!-- <p>暂未开放</p> -->
    <page-main>
      <div class="main-body-header" slot="header">
        <span class="main-title">新闻信息</span>
        <div style="display: flex; align-items: center;white-space: nowrap;">
          <label>所属板块 </label>
          <el-select
                v-model="elmentPar.categorySub"
                filterable
                clearable
                size="mini"
                @change="getNewsList"
              >
                <el-option label="热门新闻" value="热门新闻" />
                <el-option label="最新活动" value="最新活动" />
              </el-select>
              <label>类别 </label>
          <el-select
                v-model="elmentPar.category"
                filterable
                clearable
                size="mini"
                @change="getNewsList"
              >
                <el-option label="七腾视频" value="七腾视频" />
                <el-option label="焦点新闻" value="焦点新闻" />
              </el-select>
          <el-input
            style="margin:0px 8px"
            size="mini"
            placeholder="可按名称、内容、日期搜索"
            v-model="elmentPar.searchValue"
            @keyup.enter.native="getNewsList"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="getNewsList"
            ></el-button>
          </el-input>
         
          <el-button size="mini" type="primary" @click="editNews('add', null)"
            >新增新闻</el-button
          >
          <el-button size="mini" type="primary" @click="getDoSync"
            >同步于微信公众号</el-button
          >
        </div>
      </div>
      <div class="table-box" slot="body">
        <el-table
          :data="tableData"
          v-loading="loading"
          height="500px"
          style="font-size: 14px"
        >
          <el-table-column
            label="#"
            type="index"
            width="60"
          />
          <el-table-column
            label="时间"
            prop="issueDate"
            width="180"
          />
          <el-table-column
            label="名称"
            prop="name"
            width="150"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="所属模块"
            prop="belong"
            width="150"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="素材"
            prop="element"
            width="120px"
          >
            <template slot-scope="scope">
              <div class="demo-image__preview">
                <el-image
                v-if="scope.row.type==='image'"
                  style="width: 80px; height: 80px"
                  :src="scope.row.newsPcImg"
                  :preview-src-list="[scope.row.newsPcImg]"
                  referrerPolicy="no-referrer"
                >
                </el-image>
                <video
               
                width="220"
                  height="170"
                  v-else-if="scope.row.type === 'video' && scope.row.newsPcImg"
                  autoplay="autoplay"
                  loop
                  muted
                  preload
                  class="video"
                  type="video/mp4"
                  :src="scope.row.newsPcImg"
                ></video>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            label="描述"
            prop="dec"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            label="链接"
            prop="link"
            :show-overflow-tooltip="true"
            width="180"
          />
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                @click="editNews('edit', scope.row)"
                >编辑</el-button
              >
              <el-popconfirm
                :title="'确定删除【' + scope.row.name + '】新闻吗？'"
                @confirm="deleteNewsById(scope.row.newsId)"
              >
                <el-button
                  slot="reference"
                  size="mini"
                  style="margin-left: 10px"
                  type="text"
                  >删除</el-button
                >
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination" style="margin-top: 0px; text-align: end">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="elmentPar.pageNum"
            :page-sizes="[50, 100, 150, 200]"
            :page-size="elmentPar.pageSize"
            layout="total,sizes, prev, pager, next,jumper"
            :total="pageTotal"
          >
          </el-pagination>
        </div>
      </div>
    </page-main>

    <!-- 新增新闻 -->
    <el-dialog
      :title="dialogNews.titile"
      :visible.sync="dialogNews.visiable"
      width="60%"
      top="5vh"
    >
      <el-form
        ref="newForm"
        :model="newForm"
        :rules="newsRules"
        label-width="100px"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="名称" prop="name">
              <el-input size="small" v-model="newForm.name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="所属模块" prop="model">
              <el-select
                v-model="newForm.model"
                filterable
                placeholder="请选择"
                size="small"
              >
                <el-option label="新闻中心" value="-1" />
                <el-option
                  v-for="(item, index) in belongList"
                  :key="index"
                  :label="item.solutionName"
                  :value="item.solutionId.toString()"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="发布时间" prop="issueDate">
              <el-date-picker
                size="small"
                v-model="newForm.issueDate"
                type="datetime"
                placeholder="选择时间"
                format="yyyy-MM-dd HH:mm"
                value-format="yyyy-MM-dd HH:mm"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="链接">
              <el-input size="small" v-model="newForm.link"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="类别" v-if="newForm.model === '-1'">
              <el-select
                v-model="newForm.category"
                filterable
                clearable
                placeholder="请选择"
                size="small"
              >
                <el-option label="七腾视频" value="七腾视频" />
                <el-option label="焦点新闻" value="焦点新闻" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="新闻板块"
              v-if="newForm.category === '焦点新闻'"
            >
              <el-select
                v-model="newForm.categorySub"
                filterable
                clearable
                placeholder="请选择新闻板块"
                size="small"
              >
                <el-option label="热门新闻" value="热门新闻" />
                <el-option label="最新活动" value="最新活动" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="序号"
              v-if="newForm.category === '焦点新闻'"
            >
            <el-input
                type="number"
                size="small"
                v-model="newForm.sort"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="描述信息">
              <el-input
                type="textarea"
                size="small"
                :autosize="{ minRows: 3 }"
                v-model="newForm.desc"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="PC端元素">
          <el-row :gutter="20">
            <el-col :span="10">
              <div>
                <img
                  v-if="newForm.type === 'image' && newForm.newsPcImg"
                  width="220"
                  height="170"
                  :src="newForm.newsPcImg"
                  referrerPolicy="no-referrer"
                />
                <video
                width="220"
                  height="170"
                  v-if="newForm.type === 'video' && newForm.newsPcImg"
                  autoplay="autoplay"
                  loop
                  muted
                  preload
                  class="video"
                  type="video/mp4"
                  :src="newForm.newsPcImg"
                ></video>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUpload"
                class="upload-demo"
                :action="uploadUrl"
                :on-success="
                  (res, file) => {
                    uploadSuccess(res, file, 'newPc');
                  }
                "
                :before-upload="
                  (file) => {
                    uploadProgress(file, 'newPc');
                  }
                "
                :headers="uploadHeaders"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  大小不超过100MB{{
                    newForm.category === "七腾视频" ? "视频" : "图片"
                  }}
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="手机端元素">
          <el-row :gutter="20">
            <el-col :span="10">
              <div>
                <img
                  v-if="newForm.type === 'image' && newForm.newsPhoneImg"
                  width="220"
                  height="170"
                  :src="newForm.newsPhoneImg"
                  referrerPolicy="no-referrer"
                />
                <video
                width="220"
                  height="170"
                  v-if="newForm.type === 'video' && newForm.newsPhoneImg"
                  autoplay="autoplay"
                  loop
                  muted
                  preload
                  class="video"
                  type="video/mp4"
                  :src="newForm.newsPhoneImg"
                ></video>
              </div>
            </el-col>
            <el-col :span="12">
              <el-upload
                ref="avatarUploadPhone"
                class="upload-demo"
                :action="uploadUrl"
                :on-success="
                  (res, file) => {
                    uploadSuccess(res, file, 'newPhone');
                  }
                "
                :before-upload="
                  (file) => {
                    uploadProgress(file, 'newPhone');
                  }
                "
                :headers="uploadHeaders"
              >
                <el-button size="mini" type="primary">点击上传</el-button>
                <div class="el-upload__tip" slot="tip">
                  大小不超过100MB的{{
                    newForm.category === "七腾视频" ? "视频" : "图片"
                  }}
                </div>
              </el-upload>
            </el-col>
          </el-row>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="备注">
              <el-input size="small" v-model="newForm.remark"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNews.visiable = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom('newForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import index from "./index.js";
export default index;
</script>
<style scoped lang="scss">
@import "../common.scss";
</style>
